<template>
  <div>
    <container>
      <div slot="right_box_content">
        <component
          ref="right"
          :is="curRightComponent"
          :id="detailId"
        />
      </div>
      <div slot="bottom_box_content">
        <component
          :is="curBottomComponent"
          :id="detailId"
          @reloadRight="reloadRightData"
        ></component>
      </div>
      <div slot="bg_map"></div>

    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'

export default {
  mixins: [sysMixins],
  components: {
    Container,
    Bottom: () => import('./components/TaskDetail.vue'),
    Right: () => import('./components/TaskDetailSummary.vue')
  },
  data () {
    return {
      detailId: null
    }
  },
  mounted () {
    if (!this.$route.params.id || isNaN(Number(this.$route.params.id))) {
      this.$Notice.error({ desc: '非法访问，缺少必要参数' })
      this.$router.push({ name: 'construction-apply-index' })
      return
    }

    this.detailId = Number(this.$route.params.id)
    this.setShowLeftBlock(false)
    this.setShowRightBlock(true)
    this.setShowMap(false)

    this.setBottomHeight('90%')
    this.setBottomComponent('Bottom')
    this.setRightComponent('Right')
  },
  methods: {
    /**
     * 重新加载右侧数据
     */
    reloadRightData () {
      this.$refs.right.initData()
    }
  },
  destroyed () {
    // 重置state，并注销moudle
    // this.$store.commit('reset_state')
    // this.$store.unregisterModule('comAsset')
  }
}
</script>
